import { useMemo } from "react";
import { orderBy } from "lodash";
import { EMPTY, forkJoin } from "rxjs";
import { map, pluck } from "rxjs/operators";
import { useObservable, fetchAsObservable, fetcher } from "fetcher!sofe";
import { canopyPaymentsQueries } from "billing-ui/externals!sofe";
import { genQueryKey, useQuery } from "src/react-query";

export const allFilter = {
  due_date: { order: "asc" },
  draft: {
    filter_params: false,
  },
};

export const outstandingFilter = {
  due_date: { order: "asc" },
  status: {
    filter_params: [
      "Current",
      "1-30 days past due",
      "31-60 days past due",
      "61-90 days past due",
      "91+ days past due",
    ],
  },
  draft: {
    filter_params: false,
  },
};

export const useCanopyPaymentsQuery = (override) => {
  const { data } = useQuery(canopyPaymentsQueries.paymentsStatus({ override }));
  return {
    amexEnabled:
      !data?.amex_opt_out && data?.amex_submitted_status === "APPROVED",
    hasAdyen: data?.has_adyen,
    hasCanopyPayments: data?.is_active || override,
    hasPaysafe: data?.has_paysafe,
    surchargeEnabled: data?.surcharge,
    surchargeFeeText: data?.surcharge_fee,
    surchargeViewed: data?.surcharge_viewed,
  };
};

export function useClientUsers(clientId) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: genQueryKey("usersAndAdmins", { clientId }),
    queryFn: () =>
      fetcher(`/api/clients/${clientId}/users-and-admins`).then(
        (res) => res.users
      ),
    staleTime: 1000 * 60 * 5,
    enabled: !!clientId,
  });

  return {
    results: data || [],
    loading: isLoading,
    error,
    resubscribe: refetch,
  };
}

export const useBalance = (
  clientId,
  tenantBillingEnabled,
  hasBillingPermissions
) => {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: genQueryKey("outstandingBalance", { clientId }),
    queryFn: () =>
      fetcher(`/api/dashboard/invoices/outstanding?client_id=${clientId}`),
    staleTime: 1000 * 60 * 3,
    enabled: !!clientId && !!tenantBillingEnabled && !!hasBillingPermissions,
  });
  return {
    results: data || 0,
    loading: isLoading,
    error,
    resubscribe: refetch,
  };
};

export const useInvoices = (clientId, includePaid = false) => {
  const obs = useMemo(() => {
    return fetchAsObservable(
      `/api/clients/${clientId}/invoices:search?limit=999999&page=1`,
      {
        method: "POST",
        body: includePaid ? allFilter : outstandingFilter,
      }
    ).pipe(
      pluck("invoices"),
      map((invoices) => ({
        invoices: invoices.filter((invoice) => invoice.status !== "paid"),
        paidInvoices: invoices.filter((invoice) => invoice.status === "paid"),
      }))
    );
  }, [clientId, includePaid]);

  return useObservable(obs, {
    initialResults: { invoices: [], paidInvoices: [] },
  });
};

export function usePaymentHistory(clientId) {
  const obs = useMemo(() => {
    const payments = fetchAsObservable(
      "/api/payments:search?limit=999999999&page=1",
      {
        method: "POST",
        body: { client_id: { filter_params: [clientId] } },
      }
    ).pipe(pluck("payments"));

    const refunds = fetchAsObservable(
      "/api/payments:search?limit=999999999&page=1",
      {
        method: "POST",
        body: { client_id: { filter_params: [clientId] }, refunds_only: true },
      }
    ).pipe(pluck("payments"));

    return forkJoin({ payments, refunds }).pipe(
      map(({ payments, refunds }) => {
        return orderBy([...payments, ...refunds], "date", "desc");
      })
    );
  }, [clientId]);

  return useObservable(obs, { initialResults: [] });
}

export function useUpcomingPayments(clientId) {
  const obs = useMemo(() => {
    return fetchAsObservable(
      "/api/payments/recurrences:search?scheduled_only=true&limit=999999999&page=1",
      {
        method: "POST",
        body: { client_name: { filter_params: [clientId] } },
      }
    ).pipe(pluck("recurrences"));
  }, [clientId]);

  return useObservable(obs, { initialResults: null });
}

export function useScheduledPayment(paymentId) {
  const obs = useMemo(() => {
    if (paymentId) {
      return fetchAsObservable(`/api/payments/recurrences/${paymentId}`).pipe(
        pluck("recurrences")
      );
    } else {
      return EMPTY;
    }
  }, [paymentId]);

  return useObservable(obs, { initialResults: {} });
}

export function useCredits(clientId) {
  const obs = useMemo(() => {
    return fetchAsObservable(
      `/api/clients/${clientId}/credits?limit=999999999&page=1`
    ).pipe(pluck("credits"));
  }, [clientId]);

  return useObservable(obs, { initialResults: null });
}
